import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Braese" mdxType="Columns">
      <h5>{`Wir unterstützen unsere Kunden - ohne Kompromisse`}</h5>
      <p>{`Gemeinsam entwickeln wir Ideen, wie wir Sie beim Erreichen Ihrer strategischen Ziele unterstützen können und begleiten Sie bei der Realisierung Ihrer innovativen Ideen. Besondere Expertise hat sich Hitabis als Full-Service-Dienstleister in Unternehmen mit komplexen organisatorischen Abläufen erarbeitet. Probieren Sie es aus!`}</p>
      <h2>{`Wir bieten:`}</h2>
      <ul>
        <li parentName="ul">{`Projektmanagement von Analyse über Umsetzung und Test bis Support, natürlich mit umfassender Dokumentation`}</li>
        <li parentName="ul">{`Migration von Datenbanken und Anwendungen auf einheitliche Plattformen und neue Strukturen`}</li>
        <li parentName="ul">{`Tuning von Datenbanken und Prozessen zur Erhöhung der Leistungsfähigkeit`}</li>
        <li parentName="ul">{`Support bei der Einführung neuer Lösungen und Integration in vorhandene IT-Strukturen`}</li>
        <li parentName="ul">{`Private Cloud Services nach Bedarf - von Cloud-Diensten über Virtualisierung bis zur Einbindung traditioneller Infrastrukturen`}</li>
        <li parentName="ul">{`Service-Pakete für einzelne Aspekte Ihrer IT-Infrastruktur`}</li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      